import { useMutation } from '@apollo/client';
import { useFilterState } from '../components/cap';
import { getRentablesPrice } from '../components/checkoutv2/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { formatjobsiteZip, generateSKU, extractCatAndClass, getAccessoriesAndAddOnsDetails } from '../components/global/utils/commonUtils';
import { removeZeroPriceItems, getAvailableConsumablesConfig } from '../components/cart/utils/helper';
import { FORCE_ITEM } from '../components/checkoutv2/constants';
import { STOCK_CLASS_FUEL, STOCK_CLASS_MISCELLANEOUS } from '../components/molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';
import { logError } from '../components/global/utils/logger';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { useCheckUser } from './useCheckUser';
import { SET_CONSUMABLES, SET_SAVED_AVAILABLE_QUANTITY } from '../aem-core-components/actions/constants';
import { getConsumablesAction } from '../aem-core-components/actions/consumables';
import { useUserContext } from '../aem-core-components/context/UserContext';
import mutation_consumables from '../aem-core-components/queries/mutation_consumables.graphql';
import useCartEstimate from '../components/App/hooks/useCartEstimate';
import { useCartState } from '../contexts/cart';
import { STORAGE_CONFIG } from '../constants/storageConfig';

const useConsumables = () => {
    const [consumablesGQL] = useMutation(mutation_consumables);
    const [{ getStartDate, getEndDate }] = useCartEstimate();
    const [{ userProfile }] = useUserContext();
    const [{ cart, isCreditNewAddress, userAccount, howToGetYourOrderDetails}, dispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const userType = useCheckUser();
    const cartItems = cart?.items || [];

    const { selectedStoreDetails } = howToGetYourOrderDetails;
    const handlePayloadPC = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return projectDetails?.locationPC;
        } else {
            return viewCart?.pc;
        }
    };
    const handlePayloadAccount = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return userAccount?.accountNumber;
        } else {
            return '';
        }
    };
    const handlePayloadJobNumber = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return projectDetails?.selectedProjectJobId;
        } else {
            return '';
        }
    };

    const setRentablesPrice = async (items, pc) => {
        try {
            let zipCode = viewCart?.jobSiteZip || projectDetails?.selectedProjectZip || '';

            const drivingDistance = JSON.parse(
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP) || '{}'
            )?.drivingDistanceFromJobsite;

            const browseLocationOBJ = JSON.parse(
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}'
            )?.distance;
            let payload = {
                cartId: String(cart?.id) || '',
                pc: pc || handlePayloadPC(),
                jobSiteAddr1: viewCart?.location || projectDetails?.projectAddress1 || '',
                jobSiteAddr2: viewCart?.jobSiteAddr2 || projectDetails?.projectAddress2 || '',
                jobSiteCity: viewCart?.jobSiteCity || projectDetails?.selectedProjectCity || '',
                jobSiteState: viewCart?.jobSiteState || projectDetails?.selectedProjectState || '',
                jobSiteZip: formatjobsiteZip(zipCode),
                dlSt: userType === USER_TYPE.CASH ? userProfile?.dlState : '',
                dlNumber: userType === USER_TYPE.CASH ? userProfile?.dlNumber : '',
                startDateAndTime: startDate ? getStartDate() : '',
                endDateAndTime: endDate ? getEndDate() : '',
                lat: String(viewCart?.lat) || String(projectDetails?.selectedProjectLatititude) || '',
                long: String(viewCart?.long) || String(projectDetails?.selectedProjectLongitude) || '',
                distance: viewCart?.isInStorePickup ? 0 : drivingDistance || browseLocationOBJ || -1,
                timeType: viewCart?.isInStorePickup ? 'Pickup' : 'Delivery',
                lastResort: selectedStoreDetails?.isLastResortBranch ? true : false,
                fulfillment: selectedStoreDetails?.fulfillmentPercent || '',
                pcLat: String(viewCart?.pcLat) || '',
                pcLong: String(viewCart?.pcLong) || '',
                projectId: String(projectDetails?.selectedProjectJobId) || '',
                isDeliveryEstimate: viewCart?.isInStorePickup ? false : true
            };
            let payloadCredit = {
                ...payload,
                account: userAccount?.accountNumber
            };
            let rentablesWithPriceMap = {};
            if (items?.length > 0) {
                const mainCartItems = cartItems?.map(prodObj => {
                    const { sku = '' } = prodObj?.product;
                    const quantity = prodObj?.quantity;
                    const { catId, classId } = extractCatAndClass(sku);
                    return {
                        catId,
                        classId,
                        quantity
                    };
                });

                let payloadWithRentables =
                    userType === USER_TYPE.CREDIT
                        ? { ...payloadCredit, equipment: mainCartItems, salesEquipments: [] }
                        : { ...payload, equipment: mainCartItems, salesEquipments: [] };
                for (let i in items) {
                    for (let j in items[i].rentables) {
                        payloadWithRentables = {
                            ...payloadWithRentables,
                            equipment: [
                                ...payloadWithRentables.equipment,
                                {
                                    catId: ('000' + items[i].rentables[j].rentalCat).slice(-3),
                                    classId: ('0000' + items[i].rentables[j].rentalClass).slice(-4),
                                    quantity: 1
                                }
                            ]
                        };
                    }

                    items[i].consumables.forEach(consumable => {
                        consumable?.specGroups.forEach(specGroup => {
                            specGroup?.items
                            .forEach(item => {
                                if (item?.forceItem === FORCE_ITEM.YES) {
                                    payloadWithRentables = {
                                        ...payloadWithRentables,
                                        salesEquipments: [
                                            ...payloadWithRentables.salesEquipments,
                                            {
                                                itemNumber: item?.itemNumber,
                                                quantity: parseInt(item?.minQuantity),
                                                unitPrice: parseFloat(item?.sellingPrice),
                                                stockClass: item?.stockClass
                                            }
                                        ]
                                    };
                                }
                            });
                        });
                    });
                }

                if (payloadWithRentables?.equipment?.length > 0 || payloadWithRentables?.salesEquipments?.length > 0) {
                    const { data, error } = await getRentablesPrice(payloadWithRentables);
                    if (data) {
                        for (let k in data?.estimate?.itemizedCharges?.products) {
                            rentablesWithPriceMap[
                                generateSKU(
                                    data.estimate.itemizedCharges.products[k].catId,
                                    data.estimate.itemizedCharges.products[k].classId
                                )
                            ] = data?.estimate?.itemizedCharges?.products[k]?.unitRentalCost;
                        }
                    }
                    data?.estimate?.itemizedCharges?.salesItems.forEach(item => {
                        const key = item?.itemNumber + item?.stockClass;
                        rentablesWithPriceMap[key] = item?.unitPrice;
                    });
                }
            }
            return rentablesWithPriceMap;
        } catch (error) {
            logError(error, false, 'setRentablesPrice');
        }
    };

    const excludeForcedFuelItems = (response) => {
        return response?.map((suggestedItem) => ({
            ...suggestedItem,
            rentables: suggestedItem?.rentables,
            consumables: suggestedItem?.consumables
                .map((consumable) => ({
                    ...consumable,
                    specGroups: consumable?.specGroups
                        .map((specGroup) => ({
                            ...specGroup,
                            items: specGroup?.items?.filter(
                                (item) =>
                                    !(
                                        item?.forceItem === FORCE_ITEM.YES &&
                                        item?.stockClass?.toUpperCase() === STOCK_CLASS_FUEL &&
                                        item?.lineItemType?.toUpperCase() === STOCK_CLASS_MISCELLANEOUS
                                    )
                            ),
                        }))
                        .filter((specGroup) => specGroup?.items?.length > 0), // Remove empty specGroups
                }))
                .filter((consumable) => consumable?.specGroups?.length > 0), // Remove empty consumables
        }));
    }
    

    const getConsumables = async (pc = '') => {
        const items = cart?.availableCartItems?.map(item => item.product.sku);
        let payloadForConsumables = {
            pcId: pc || handlePayloadPC(),
            account: handlePayloadAccount(),
            jobNumber: handlePayloadJobNumber(),
            items
        };
        try {
            dispatch({ type: 'beginLoading' });
            const response = await getConsumablesAction({
                consumablesGQL,
                payloadForConsumables
            });

            const data = await excludeForcedFuelItems(response);
            const estimates = await setRentablesPrice(data, pc);
            dispatch({
                type: SET_CONSUMABLES,
                consumables: removeZeroPriceItems(data, estimates),
                availableQuantity: getAvailableConsumablesConfig(data)
            });
            dispatch({ type: SET_SAVED_AVAILABLE_QUANTITY, key: 'rentablesWithPrice', value: estimates });
            dispatch({ type: 'endLoading' });
            return data;
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'getConsumables', [pc]);
            } else {
                logError(error, false, 'getConsumables', [pc]);
                dispatch({ type: 'endLoading' });
            }
        }
    };

    const formatAccessories = accessories => {
        try {
                const accessoriesObj = {};
                accessories?.forEach(accessory => {
                    accessoriesObj[accessory?.sku] = {
                        name: accessory?.name,
                        qty: 1,
                        itemObj: {
                            forceItem: accessory?.forceItem,
                        }
                    }
                });
            return accessoriesObj;
        } catch (error) {
            logError(error, false, 'formatAccessories');
            return {};
        }
    };

    const formatAddons = addons => {
        try {
                const addonObj = {};
                addons?.forEach(addon => {
                    const key = `${addon?.itemNumber}${addon?.stockClass}${addon?.oemStockNumber}`;
                    addonObj[key] = {
                        name: addon?.name,
                        price: parseFloat(addon?.sellingPrice),
                        qty: 1,
                        itemObj: {
                            forceItem: addon?.forceItem,
                            oemStockNumber: addon?.oemStockNumber,
                            itemNumber: addon?.itemNumber,
                            lineItemType: addon?.lineItemType,
                            stockClass: addon?.stockClass
                        }
                    };
                });
                return addonObj;
        } catch (error) {
            logError(error, false, 'formatAddons');
            return {};
        }
    };

    const getConsumablesForAddToCart = (forcedItems, catsku) => {
        try {
            const forcedAccessories = forcedItems?.rentals;
            const forcedAddOn = forcedItems?.addons;
            if (!forcedAccessories?.length && !forcedAddOn?.length) {
                return '';
            }
            const { catId, classId } = extractCatAndClass(catsku);
            return JSON.stringify([{
                cat: catId,
                class: classId,
                rentals: formatAccessories(forcedAccessories),
                addons: formatAddons(forcedAddOn)
            }]);
        } catch (error) {
            logError(error, false, 'getConsumablesForAddToCart');
        }
    }

    const api = { dispatch, getConsumables, getConsumablesForAddToCart };
    return [api];
};

export default useConsumables;
